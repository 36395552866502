const euCountries = [
    {name: "Spain", code: "ES", currencyCode: "EUR", featured: false, states: []},
    {name: "Åland", code: "AX", currencyCode: "EUR", featured: false, states: []},
    {name: "Albania", code: "AL", currencyCode: "ALL", featured: false, states: []},
    {name: "Andorra", code: "AD", currencyCode: "EUR", featured: false, states: []},
    {name: "Austria", code: "AT", currencyCode: "EUR", featured: false, states: []},
    {name: "Belarus", code: "BY", currencyCode: "BYR", featured: false, states: []},
    {name: "Belgium", code: "BE", currencyCode: "EUR", featured: false, states: []},
    {name: "Bosnia and Herzegovina", code: "BA", currencyCode: "BAM", featured: false, states: []},
    {name: "Bulgaria", code: "BG", currencyCode: "BGN", featured: false, states: []},
    {name: "Croatia", code: "HR", currencyCode: "HRK", featured: false, states: []},
    {name: "Cyprus", code: "CY", currencyCode: "EUR", featured: false, states: []},
    {name: "Czechia", code: "CZ", currencyCode: "CZK", featured: false, states: []},
    {name: "Denmark", code: "DK", currencyCode: "DKK", featured: false, states: []},
    {name: "Estonia", code: "EE", currencyCode: "EUR", featured: false, states: []},
    {name: "Faroe Islands", code: "FO", currencyCode: "DKK", featured: false, states: []},
    {name: "Finland", code: "FI", currencyCode: "EUR", featured: false, states: []},
    {name: "France", code: "FR", currencyCode: "EUR", featured: false, states: []},
    {name: "Germany", code: "DE", currencyCode: "EUR", featured: false, states: []},
    {name: "Gibraltar", code: "GI", currencyCode: "GIP", featured: false, states: []},
    {name: "Greece", code: "GR", currencyCode: "EUR", featured: false, states: []},
    {name: "Guernsey", code: "GG", currencyCode: "GBP", featured: false, states: []},
    {name: "Hungary", code: "HU", currencyCode: "HUF", featured: false, states: []},
    {name: "Iceland", code: "IS", currencyCode: "ISK", featured: false, states: []},
    {name: "Ireland", code: "IE", currencyCode: "EUR", featured: false, states: []},
    {name: "Isle of Man", code: "IM", currencyCode: "GBP", featured: false, states: []},
    {name: "Italy", code: "IT", currencyCode: "EUR", featured: false, states: []},
    {name: "Jersey", code: "JE", currencyCode: "GBP", featured: false, states: []},
    {name: "Kosovo", code: "XK", currencyCode: "EUR", featured: false, states: []},
    {name: "Latvia", code: "LV", currencyCode: "LVL", featured: false, states: []},
    {name: "Liechtenstein", code: "LI", currencyCode: "CHF", featured: false, states: []},
    {name: "Lithuania", code: "LT", currencyCode: "LTL", featured: false, states: []},
    {name: "Luxembourg", code: "LU", currencyCode: "EUR", featured: false, states: []},
    {name: "Macedonia", code: "MK", currencyCode: "MKD", featured: false, states: []},
    {name: "Malta", code: "MT", currencyCode: "EUR", featured: false, states: []},
    {name: "Moldova", code: "MD", currencyCode: "MDL", featured: false, states: []},
    {name: "Monaco", code: "MC", currencyCode: "EUR", featured: false, states: []},
    {name: "Montenegro", code: "ME", currencyCode: "EUR", featured: false, states: []},
    {name: "Netherlands", code: "NL", currencyCode: "EUR", featured: false, states: []},
    {name: "Norway", code: "NO", currencyCode: "NOK", featured: false, states: []},
    {name: "Poland", code: "PL", currencyCode: "PLN", featured: false, states: []},
    {name: "Portugal", code: "PT", currencyCode: "EUR", featured: false, states: []},
    {name: "Romania", code: "RO", currencyCode: "RON", featured: false, states: []},
    {name: "Russia", code: "RU", currencyCode: "RUB", featured: false, states: []},
    {name: "San Marino", code: "SM", currencyCode: "EUR", featured: false, states: []},
    {name: "Serbia", code: "RS", currencyCode: "RSD", featured: false, states: []},
    {name: "Slovakia", code: "SK", currencyCode: "EUR", featured: false, states: []},
    {name: "Slovenia", code: "SI", currencyCode: "EUR", featured: false, states: []},
    {name: "Svalbard and Jan Mayen", code: "SJ", currencyCode: "NOK", featured: false, states: []},
    {name: "Sweden", code: "SE", currencyCode: "SEK", featured: false, states: []},
    {name: "Switzerland", code: "CH", currencyCode: "CHF", featured: false, states: []},
    {name: "Turkey", code: "TR", currencyCode: "TRY", featured: false, states: []},
    {name: "Ukraine", code: "UA", currencyCode: "UAH", featured: false, states: []},
    {name: "Vatican City", code: "VA", currencyCode: "EUR", featured: false, states: []}
];

const usCountries = [
    {name: "United States", code: "US", currencyCode: "USD", featured: false, states: []}
];

const ukCountries = [
    {name: "United Kingdom", code: "GB", currencyCode: "GBP", featured: false, states: []}
];

const nonIntCountries = euCountries.concat(usCountries).concat(ukCountries);

var restCountries = [];

exports.importEuCountriesToSnipcart = () => {
    return euCountries;
}

exports.importUSCountriesToSnipcart = () => {
    return usCountries;
}

exports.importUKCountriesToSnipcart = () => {
    return ukCountries;
}

exports.importIntCountriesToSnipcart = () => {
    return restCountries;
}

exports.exportIntCountriesFromSnipcart = async(initialCountries) => {
    if (restCountries.length === 0) {
        initialCountries.forEach(country => {
            for (let index = 0; index < nonIntCountries.length; index++) {
                const element = nonIntCountries[index];
                if (element.code === country.code) {
                    return;
                }
            }
            restCountries.push(country);
        });
    }
}