import React from 'react';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import safeIcon from '../resources/images/icons/safe-icon.svg';
import returnsIcon from '../resources/images/icons/returns-icon.svg';
import customerIcon from '../resources/images/icons/customer-support-icon.svg';
import instagramIcon from '../resources/images/icons/instagram-icon.svg';
import facebookIcon from '../resources/images/icons/facebook-icon.svg';
import NewsletterForm from './newsletterForm';
import GrantPopup from './grantPopup';

class Footer extends React.Component {

    /**
     * @function goUp
     * @desc this function scrolls to the top of the page
     */
    goUp = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const contactinfo = JSON.parse(this.props.contactinfo);
        return(
            <>
                <div className="footer__go-up">
                    <button onClick={this.goUp} onKeyDown={this.goUp} aria-label={i18next.t("FOOTER_GOUP_ARIA")}>
                        <FontAwesomeIcon icon={faChevronUp} />
                    </button>
                </div>
                <div className="footer">
                <div className="footer__up">
                    <div className="footer__up-wrapper">
                        <div className="footer__up-items">
                            <div className="footer__up-item">
                                <div className="footer__item-support">
                                    <img className="lazyload" data-src={customerIcon} alt="customer help icon" />
                                </div>
                                <div>{i18next.t("FOOTER_SUPPORT")}</div>
                            </div>
                            <div className="footer__up-item">
                                <div className="footer__item-safe">
                                    <img className="lazyload" data-src={safeIcon} alt="safe icon" />
                                </div>
                                <div>{i18next.t("FOOTER_SAVE")}</div>
                            </div>
                            <div className="footer__up-item">
                                <div className="footer__item-return">
                                    <img className="lazyload" data-src={returnsIcon} alt="returns icon" />
                                </div>
                                <div>{i18next.t("FOOTER_RETURN")}</div>
                            </div>
                        </div>
                        <div className="footer__up-newsletter">
                            <NewsletterForm/>
                        </div>
                    </div>
                </div>
                
                <div className="footer__center">
                    <div className="footer__center-wrapper">
                        <div className="footer__center-column contact">
                            <div className="footer__menu-list footer__menu-list--social">
                                <span className="footer__menu-title">{i18next.t("FOOTER_SOCIAL")}</span>
                                <ul className="social-buttons-list">
                                    <li>
                                        <a className="facebook-btn" href="" aria-label="Facebook">
                                            <img className="lazyload" data-src={facebookIcon} alt="facebook icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a className="instagram-btn" href="" aria-label="Instagram">
                                            <img className="lazyload" data-src={instagramIcon} alt="instagram icon" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer__contact">
                                <div className="footer__contact-mail">
                                    <i className="fas fa-envelope"></i>
                                    <a href={contactinfo.emailLink} target="_blank">{contactinfo.email}</a>
                                </div>
                            </div>
                        </div>
                        <div className="footer__center-column links">
                            <div className="footer__menu-list"><ul dangerouslySetInnerHTML={{__html: this.props.footer.footerLinksList}}></ul></div>
                        </div>
                        <div className="footer__center-column payments">
                            <div className="footer__payments" dangerouslySetInnerHTML={{__html: this.props.footer.footerPayments}}></div>
                            <GrantPopup />
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default Footer;