import React from 'react';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';

const modalStyles = {
    content : {
        top         : '50%',
        left        : '50%',
        right       : 'auto',
        bottom      : 'auto',
        marginRight : '-50%',
        transform   : 'translate(-50%, -50%)'
    }
};

class GrantPopup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showPopupGrant: false
        }
    }
    
    /**
     * @function showPopupGrant
     * @desc this function shows the popup with the mandatory texts for the grants
     */
    showPopupGrant = () => {
        this.setState({
            showPopupGrant: true
        });
    }

    /**
     * @function closePopupGrant
     * @desc this function closes the popup with the mandatory texts for the grants
     */
    closePopupGrant = () => {
        this.setState({
            showPopupGrant: false
        });
    }

    render() {
        return(
            <>
                <div className="popup__logos" onClick={this.showPopupGrant}>
                    <span>{i18next.t("FOOTER_GRANT")}</span>
                    <img className="lazyload ue" src="https://www.datocms-assets.com/34634/1609141815-logo-ue.png" alt="ue"/>
                    <img className="lazyload igape" src="https://www.datocms-assets.com/34634/1604562806-logo-igape.png" alt="igape"/>
                </div>
                <Modal
                    isOpen={this.state.showPopupGrant}
                    ariaHideApp={false}
                    style={modalStyles}
                    onRequestClose={this.closePopupGrant}
                >
                    <div className="popup__grantpopup-close" onClick={this.closePopupGrant}>
                        <FontAwesomeIcon className="icon-close" icon="times" />
                    </div>
                    <div className="popup__grantpopup-main">
                        <div className="popup__grantpopup-img">
                            <img className="lazyload" src="https://www.datocms-assets.com/34634/1604562607-igapeaxuda.png" alt="igape grant"/>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default GrantPopup;
