import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import aboutUsIcon from '../resources/images/icons/aboutus-icon.svg';

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isClosed: true
        }
    }

    /**
     * @function openAboutUs
     * @desc this function open about us modal
     */
    openAboutUs = () => {
        this.setState({
            isClosed: false
        });
    }

    /**
     * @function closeAboutUs
     * @desc this function close about us modal
     */
    closeAboutUs = () => {
        this.setState({
            isClosed: true
        });
    }

    render(){
        return (
            <>
                <div 
                    className={this.state.isClosed ? "about-us__fade" : "about-us__fade opened"}
                    onClick={this.closeAboutUs}
                ></div>
                <div className="about-us">
                    <div 
                        className={this.state.isClosed ? "about-us__closed" : "about-us__closed hide"}
                        onClick={this.openAboutUs}
                    >
                        <img className="lazyload mainIcon" data-src={aboutUsIcon} alt="About Us"/>
                    </div>
                    
                    <div className={this.state.isClosed ? "about-us__opened" : "about-us__opened opened"}>
                        <div className="about-us__title">
                            <img className="lazyload mainIcon" data-src={aboutUsIcon} alt="About Us"/>
                            <span>{i18next.t("ABOUT_US_TITLE")}</span>
                        </div>
                        <div className="about-us__content">
                            <a className="about-us__phone" href={this.props.contactinfo.phoneLink} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon="phone-alt"/>
                                {this.props.contactinfo.phone}
                            </a>
                            <a className="about-us__mail" href={this.props.contactinfo.emailLink} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon="envelope"/>
                                {this.props.contactinfo.email}
                            </a>
                            <a className="about-us__whatsapp" href={this.props.contactinfo.whatsappLink} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={["fab" , "whatsapp"]}/>
                                {this.props.contactinfo.whatsapp}
                            </a>
                            <span className="about-us__weekdays">
                                {this.props.contactinfo.opening.days} {this.props.contactinfo.opening.hours}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AboutUs;