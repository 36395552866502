import React from 'react';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Recaptcha from 'react-invisible-recaptcha';

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

const StyledTextField = withStyles({
    root: {
        '& label': {
          color: '#b0b0b0',
          "font-size": "1.4rem",
          "font-family": "Raleway Medium, sans-serif",
          "letter-spacing": "0.12em",
          '&.Mui-focused': {
            "font-family": "Raleway Medium, sans-serif",
              color: "#777",
          },
        },
        '& div': {
            '&:hover': {
                '&:before': {
                    "border-bottom": "solid 0.1rem #777 !important",
                },
                '&:after': {
                    "border-bottom": "solid 0.1rem #777 !important",
                },
            },
            '&.Mui-focused': {
                '&:before': {
                    "border-bottom": "solid 0.1rem #777 !important",
                },
                '&:after': {
                    "border-bottom": "solid 0.1rem #777 !important",
                },
            },
            '&:before': {
                "border-bottom": "solid 0.1rem #b0b0b0",
            },
            '&:after': {
                "border-bottom": "solid 0.1rem #b0b0b0",
            },
            '& input': {
                color: '#b0b0b0',
                "font-size": "1.4rem",
                "font-family": "Raleway Medium, sans-serif",
            }
        }
    },
})((props) => <TextField  type="text" {...props} />);

class NewsletterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {"g-recaptcha-response" : ""};
    }

    componentDidMount() {
        const region = new URLSearchParams(document.location.search.substring(1)).get("region");
        let store = document.getElementById('countrySelect').value;
        if (Cookies.get('store') === undefined && region !== null) {
            switch (region) {
                case "europe":
                    store="eu"
                    break;
                case "us":
                    store="us";
                    break;
                case "uk":
                    store="uk";
                    break;
                case "rest":
                    store="int";
                    break;
                default:
            }
        } else if (Cookies.get('store') !== undefined) {
            store = Cookies.get('store');
        }
        this.setState({
            "store" : store,
            "lang" : i18next.language
        });
    }

    handleChange = (e) => {
        document.getElementsByClassName("formnewsletter-privacy")[0].classList.add("error");
        this.setState({ [e.target.name]: e.target.value });
    }
    
    handleRecaptcha = value => {
        this.setState({ "g-recaptcha-response": value });
    };
    
    /**
     * @function handleFormSubmit
     * @desc this function avoid the netlify response, validate the data and invokes the google recaptcha
     */
    handleFormSubmit = (e) => {
        e.preventDefault();

        //Reset messages
        document.getElementsByClassName("formnewsletter-privacy")[0].classList.remove("error")

        //Check required fields
        if(this.state.email === undefined) {
            this.setState({ "email": "" });
        }
        if (!document.getElementById("agree-policy").checked) {
            document.getElementsByClassName("formnewsletter-privacy")[0].classList.add("error");
        }
        
        //Check recaptcha
        if (this.state.email !== '' && document.getElementById("agree-policy").checked) {
            this.recaptcha.execute();
        }
    }
    
    /**
     * @function onRecaptchaResolved
     * @desc this function is invoked when back from google recaptcha
     */
    onRecaptchaResolved = () => {
        this.setState({ "g-recaptcha-response": this.recaptcha.getResponse() });
        const form = document.forms["newsletter"];
        const path = window && window.location ? window.location.pathname : "/";
        fetch(path, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state
            })
        })
        .then(() => {
            this.recaptcha.reset();
            form.classList.add("hidden");
            document.getElementById("newsletter-success").classList.remove("hidden");
            document.forms["newsletter"].classList.add("hidden");
        })
        .catch((error) => {
            this.recaptcha.reset();
            document.getElementById("newsletter-error").classList.remove("hidden");
        });
    }

    render() {
        return(
            <>
                <div className="formnewsletter">
                    <span className="formnewsletter-title">{i18next.t("FOOTER_NEWSLETTER")}</span>
                    <span id="newsletter-success" className="formnewsletter-success hidden">{i18next.t("FOOTER_NEWSLETTER_SUCCESS")}</span>
                    <span id="newsletter-error" className="formnewsletter-error hidden">{i18next.t("FOOTER_NEWSLETTER_ERROR")}</span>
                    <form
                        id="newsletter"
                        name="newsletter"
                        method="post"
                        action="/"
                        data-netlify="true"
                        data-netlify-honeypot="field-bot-detection"
                        data-netlify-recaptcha="true"
                        onSubmit={this.handleFormSubmit}>
                        <div className="formnewsletter-form">
                            <input type="hidden" name="form-name" value="newsletter" />
                            <input name="field-bot-detection" onChange={this.handleChange} className="hidden" />
                            <StyledTextField
                                name="email"
                                type="email"
                                error={this.state.email === ''}
                                helperText={this.state.email === '' ? i18next.t("CONTACTUS_FORM_REQUIRED_LABEL") : ''}
                                className="formnewsletter-input"
                                label={i18next.t("FOOTER_NEWSLETTER_PLACEHOLDER")}
                                aria-label={i18next.t("FOOTER_NEWSLETTER_PLACEHOLDER")}
                                onChange={this.handleChange}
                            />
                            <input name="store" onChange={this.handleChange} className="hidden" />
                            <input name="lang" onChange={this.handleChange} className="hidden" />
                            <button type="submit" className="g-recaptcha formnewsletter-button">{i18next.t("FOOTER_SUBMIT")}</button>
                        </div>
                        <div className="formnewsletter-privacy-wrapper">
                            <div className="formnewsletter-privacy">
                                <input type="checkbox" id="agree-policy"/>
                                <label htmlFor="agree-policy" className="formnewsletter-privacy-checkbox">
                                    {i18next.t("CONTACTUS_FORM_POLICY")}
                                    <a href={i18next.t("CONTACTUS_FORM_PRIVACY_LINK")} target="_blank" rel="noreferrer">
                                        {i18next.t("CONTACTUS_FORM_PRIVACY")}
                                    </a>
                                </label>
                            </div>
                            <Recaptcha
                                ref={ ref => this.recaptcha = ref }
                                sitekey={RECAPTCHA_KEY}
                                onResolved={this.onRecaptchaResolved}
                                locale={this.state.lang}
                                badge="inline"
                            />
                        </div>
                    </form>
                </div>
            </>
        );
    }
}

export default NewsletterForm;