import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { 
            faMobileAlt, faUserAlt, faAngleDown, faAngleUp,
            faShoppingBag, faSearch, faBars, faTimes,
            faPlus, faMinus, faLock, faRedoAlt, faPhoneAlt,
            faEnvelope, faTimesCircle, faUserCog
        } from '@fortawesome/free-solid-svg-icons';

const iconsArray = [
                    faMobileAlt, faUserAlt, faAngleDown, faAngleUp, faShoppingBag, faSearch,
                    faBars, faTimes, faPlus, faMinus, faLock, faRedoAlt, faPhoneAlt,
                    faEnvelope, faTimesCircle, faInstagram, faFacebookF, faUserCog, faWhatsapp
                ];
library.add(iconsArray);

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch();