import React from 'react';
import { I18nextProvider } from "react-i18next";
import '../style/fonts.css';
import '../utils/third-party/slick/slick.min.css';
import '../utils/third-party/slick/slick-theme.min.css';
import '../style/index.scss';
import Cookies from 'js-cookie';
import { GlobalContext } from '../context/globalContext';
import Header from '../components/header';
import '../utils/fontawesome';
import CookieConsent from 'react-cookie-consent';
import Footer from '../components/footer';
import i18next from 'i18next';
import AboutUs from '../components/about_us';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Maintenance from '../templates/maintenance';

const mobileBreakpoint = 1024;

let Snipcart = null;

const countryConfig = {
    'us': {
        'defaultCurrency': 'USD'
    },
    'eu': {
        'defaultCurrency': 'EUR',
    },
    'uk': {
        'defaultCurrency': 'GBP',
    },
    'int': {
        'defaultCurrency': 'USD',
    }
};

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            footer: '',
            currency:''
        }
    }
    
    componentDidMount() {
        // Snipcart = window.Snipcart;
        this.updateMobileFlag();
        // this.setState({
        //     footer: this.props.data.allDatoCmsFooter.edges[0].node
        // });

        //Check initial parameter "region"
        // const region = new URLSearchParams(document.location.search.substring(1)).get("region");
        // if (Cookies.get('currency') === undefined && Cookies.get('store') === undefined && region !== null) {
        //     switch (region) {
        //         case "europe":
        //             document.cookie = 'currency=EUR; path=/';
        //             document.cookie = 'store=eu; path=/';
        //             this.setState({
        //                 country: 'eu',
        //                 currency: 'eur'
        //             });
        //             break;
        //         case "us":
        //             document.cookie = 'currency=USD; path=/';
        //             document.cookie = 'store=us; path=/';
        //             this.setState({
        //                 country: 'us',
        //                 currency: 'usd'
        //             });
        //             break;
        //         case "uk":
        //             document.cookie = 'currency=GBP; path=/';
        //             document.cookie = 'store=uk; path=/';
        //             this.setState({
        //                 country: 'uk',
        //                 currency: 'gbp'
        //             });
        //             break;
        //         case "rest":
        //             document.cookie = 'currency=USD; path=/';
        //             document.cookie = 'store=int; path=/';
        //             this.setState({
        //                 country: 'int',
        //                 currency: 'usd'
        //             });
        //             break;
        //         default:
        //     }
        // }

        // if (Cookies.get('store') !== undefined) {
            //Cookie already created, ignore other parameters
        //     var store = Cookies.get('store');
        //     var selectStoreOptions = document.getElementById('countrySelect').options;
        //     for(var optionStore = 0; optionStore < selectStoreOptions.length; optionStore++) {
        //         if(selectStoreOptions[optionStore].value === store) {
        //             document.getElementById('countrySelect').selectedIndex = selectStoreOptions[optionStore].index;       
        //         }
        //     }
        // }

        // if(Cookies.get('currency') !== undefined) {
            //Cookie already created, ignore other parameters
        //     var currency = Cookies.get('currency');
        //     var selectOptions = document.getElementById('currencySelect').options;
        //     for(var option = 0; option < selectOptions.length; option++) {
        //         if(selectOptions[option].value === currency) {
        //             document.getElementById('currencySelect').selectedIndex = selectOptions[option].index;
        //         }
        //     }
        // } else {
            //Currency dependant of region
        //     this.handleCurrencyChange(countryConfig[document.getElementById('countrySelect').value].defaultCurrency);
        //     var selectOptions = document.getElementById('currencySelect').options;
        //     for(var option = 0; option < selectOptions.length; option++) {
        //         if(selectOptions[option].value === this.state.currency) {
        //             document.getElementById('currencySelect').selectedIndex = selectOptions[option].index;
        //         }
        //     }
        // }
        
        //Re-init state & cookies
        // this.setState({
        //     country: document.getElementById('countrySelect').value,
        //     currency: document.getElementById('currencySelect').value
        // });
        // document.cookie = 'currency=' + document.getElementById('currencySelect').value + '; path=/';
        // document.cookie = "store=" + document.getElementById('countrySelect').value + "; path=/";

        window.addEventListener('resize', this.updateMobileFlag);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateMobileFlag);
    }
    
    /**
     * @function updateMobileFlag
     * @desc this function check if its mobile resolution
     */
    updateMobileFlag = () => {
        this.setState({
            isMobile: window.innerWidth > mobileBreakpoint ? false : true
        });
    }

    /**
     * @function updateMobileFlag
     * @desc this function saves the searched words
     * 
     * @param {term} - string
     */
    handleSearchText = (term) => {
        this.setState({
            searchText: term
        });
    }

    /*handleCompletedOrder = (e) => {
        if(typeof window !== `undefined` && typeof window.Snipcart !== `undefined`) {
            window.Snipcart.subscribe('order.completed', function (data) {
                data.items.map((item, index) => (
                    updateStock(item.customFields[0].name.split('-')[1], item.maxQuantity, item.quantity)
                ))
            });
        }
    }*/

    /**
     * @function handleCurrencyChange
     * @desc this function change the store currency
     * 
     * @param {e}
     */
    handleCurrencyChange = (value) => {
        if (typeof document !== `undefined`) {
            document.cookie = 'currency=' + value + '; path=/';
            this.setState({
                currency: value
            });
            if(typeof Snipcart !== "undefined") {
                Snipcart.api.session.setCurrency(value);
            }
        }
    }

    /**
     * @function handleCountryChange
     * @desc this function changes the selected store
     * 
     * @param {e} - select element
     */
    handleCountryChange = (value) => {
        if (typeof document !== `undefined`) {
            document.cookie = "store=" + value + "; path=/";
            this.setState({
                country: value
            });

            if (document.getElementsByClassName("pdp-product__taxes-included") && document.getElementsByClassName("pdp-product__taxes-included").length > 0) {
                document.getElementsByClassName("pdp-product__taxes-included")[0].style.display = (value ==='eu' ? 'block' : 'none');
            }
            this.handleCurrencyChange(countryConfig[value].defaultCurrency);
        }
    }

    triggerOnChange = (element) => {
        if ("createEvent" in document) {
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent("change", false, true);
            element.dispatchEvent(evt);
        } else {
            element.fireEvent("onchange");
        }
    }

    render() {
        const data = this.props.data;
        const children = this.props.children;
        let searchText = this.state.searchText ? this.state.searchText : '';
        //this.handleCompletedOrder();
        return (
            <I18nextProvider>
            <Maintenance/>
                {/* <AboutUs contactinfo={JSON.parse(data.contactinfo.data)} />
                <div className="page">
                    <Header 
                        data={data}
                        handleSearchText={this.handleSearchText}
                        getCookie={getCookie}
                        handleCurrencyChange={this.handleCurrencyChange}
                        handleCountryChange={this.handleCountryChange}
                        currency={this.state.currency}
                    />
                    <div className="content" >
                        <GlobalContext.Provider value={{searchText: searchText, currency: this.state.currency, isMobile: this.state.isMobile}}>
                            {children}
                        </GlobalContext.Provider>
                    </div>
                    <Footer footer={this.state.footer} contactinfo={this.props.data.contactinfo.data}></Footer>
                </div>
                <CookieConsent
                    location="bottom"
                    buttonText={<FontAwesomeIcon icon="times" />}
                    declineButtonText="Decline"
                    cookieName="gatsby-gdpr-google-analytics"
                >
                    <div dangerouslySetInnerHTML={{__html: i18next.t("COOKIE_TEXT")}} />
                </CookieConsent> */}
                
            </I18nextProvider>
        )
    }
}

export default Layout;


/**
 * @function getCookie
 * @desc this function gets the cookie value
 * 
 * @param {cname} - cookie name
 */
function getCookie(cname) {
    if (typeof document !== `undefined`) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
    }
    return "";
}