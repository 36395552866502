import React from 'react';
import { Link } from 'gatsby';
import { navigate } from "gatsby";
import i18n from "../config/i18n";
import i18next from 'i18next';
import Carousel from "react-slick";
import searchIcon from '../resources/images/icons/search-icon.svg';
import { importESSnipcartTranslation } from '../locales/snipcartTranslations.js';
import { importIntCountriesToSnipcart, importEuCountriesToSnipcart, importUKCountriesToSnipcart, importUSCountriesToSnipcart, exportIntCountriesFromSnipcart } from '../resources/data/snipcartCountries.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loginIcon from '../resources/images/icons/login-icon.svg';
import cartIcon from '../resources/images/icons/cart-icon.svg';
import logoSVG from '../resources/images/logo/mainlogo.svg';
import GrantPopup from './grantPopup'

let Snipcart = null;
let searchText = '';
let brandsMenu = [];

const menuSettings = {
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: false,
            isDDMOpen: false,
            locale: ''
        }
    }

    componentDidMount = () => {
        Snipcart = window.Snipcart;
        if (this.props.getCookie('store') === 'int') {
            document.getElementById('countrySelect').selectedIndex = '1';
        } else {
            document.getElementById('countrySelect').selectedIndex = '0';
        }
        document.querySelector('html').removeAttribute('style');

        var langSelector = document.getElementById('langSelect');
        var language = langSelector[langSelector.selectedIndex].value;
        this.setState({
            locale: language
        });
        i18n.changeLanguage(language);
        window.localStorage.setItem('lang', language);

        this.handleSnipcartInitialization(language, this.props.getCookie('store'));
        this.isLoggedFunction();
        this.manageBrandsMenu();
    }

    componentDidUpdate() {
        document.getElementById('currencySelect').value = this.props.currency;
        Snipcart.api.session.setCurrency(this.props.currency);
    }

    /**
     * @function isLoggedFunction
     * @desc this function verify if the user is logged
     */
    isLoggedFunction = () => {
        if (typeof Snipcart !== "undefined") {
            if (typeof Snipcart.store !== "undefined" && Snipcart.store !== null) {
                if (Snipcart.store.getState().customer) {
                    this.setState({
                        isLogged: Snipcart.store.getState().customer.email !== undefined
                    });
                }
            } else {
                this.setState({
                    isLogged: false
                });
            }
        }
    }
    
    /**
     * @function manageBrandsMenu
     * @desc this function build the map for the Brands menu
     */
    manageBrandsMenu = () => {
        brandsMenu = [];
        this.props.data.categories.edges.map(({ node: category }) => {
            if (category.name === 'Brands' || category.name === 'Marcas') {
                category.subcategory.sort((a, b) => {return a.name > b.name ? 1:-1}).map((subcat, index, array) => {
                    if (index === 0 || array[index-1].name.substring(0,1) !== subcat.name.substring(0,1)) {
                        var brandletter = {
                            letter : subcat.name.substring(0,1),
                            brands : [subcat]
                        }
                        brandsMenu.push(brandletter);
                    } else if (array[index-1].name.substring(0,1) === subcat.name.substring(0,1)) {
                        brandsMenu[brandsMenu.length-1].brands.push(subcat);
                    } else {

                    }
                })
            }
        });
    }

    /**
     * @function closeLoginPopupOnLogout
     * @desc this function hide the login popup when you make click on logout
     */
    closeLoginPopupOnLogout = () => {
        Snipcart.api.customer.signout();
        this.setState({
            isLogged: false
        });
    }

    /**
     * @function openDDM
     * @desc this function open the DDM menu on mobile
     */
    openDDM = () => {
        this.setState({
            isDDMOpen: true
        });
        document.querySelector('html').setAttribute('style', 'overflow-y: hidden');
    }

    /**
     * @function closeDDM
     * @desc this function close the DDM menu on mobile
     */
    closeDDM = (e) => {
        this.setState({
            isDDMOpen: false
        });
        document.querySelector('html').removeAttribute('style');
    }

    /**
     * @function handleLangChange
     * @desc this function change the language selected
     * 
     * @param {value} - select element
     */
    handleLangChange = (value) => {
        if (typeof document !== 'undefined') {
            this.setState({
                locale: value
            });
            window.localStorage.setItem('lang', value);
            i18n.changeLanguage(value);
            Snipcart.api.session.setLanguage(value);
            navigate("/" + value + "/");
        }
    }

    handleSnipcartInitialization = (lang, store) => {
        if (typeof Snipcart !== 'undefined') {
            Snipcart.DEBUG = false;
            if (lang === 'es') {
                Snipcart.api.session.setLanguage('es', importESSnipcartTranslation());
            } else {
                Snipcart.api.session.setLanguage(lang);
            }
            exportIntCountriesFromSnipcart(Snipcart.store.getState().session.settings.countries);
            this.handleSnipcartStore(store);
        } else {
            document.addEventListener('snipcart.ready', () => {
                Snipcart.DEBUG = false;
                if (lang === 'es') {
                    Snipcart.api.session.setLanguage('es', importESSnipcartTranslation());
                } else {
                    Snipcart.api.session.setLanguage(lang);
                }                exportIntCountriesFromSnipcart(Snipcart.store.getState().session.settings.countries);
                this.handleSnipcartStore(store);
            });
        }
    }

    /**
     * @function handleSnipcartStore
     * @desc this function change the Snipcart store
     * 
     * @param {store}
     */
    handleSnipcartStore = (store) => {
        if (typeof Snipcart !== `undefined`) {
            if (store === 'eu') {
                Snipcart.store.getState().session.settings.countries = importEuCountriesToSnipcart();
            } else if (store === 'us') {
                Snipcart.store.getState().session.settings.countries = importUSCountriesToSnipcart();
            } else if (store === 'uk') {
                Snipcart.store.getState().session.settings.countries = importUKCountriesToSnipcart();
            } else if (store === 'int') {
                Snipcart.store.getState().session.settings.countries = importIntCountriesToSnipcart();
            }
            Snipcart.events.on('customer.registered', (authResponse) => {
                this.isLoggedFunction();
            });
            Snipcart.events.on('customer.signedin', (authResponse) => {
                this.isLoggedFunction();
            });
            Snipcart.events.on('customer.signedout', () => {
                this.isLoggedFunction();
            });
        }
    }

    /**
     * @function hideLoginIcon
     * @desc this function change the user icon if its logged or not
     * 
     * @param {hideLogin} - boolean
     */
    hideLoginIcon = (hideLogin) => {
        if (hideLogin === true) {
            document.getElementById("header-login-icon").classList.add("hidden");
            document.getElementById("header-login-icon-logged").classList.remove("hidden");
        } else {
            document.getElementById("header-login-icon").classList.remove("hidden");
            document.getElementById("header-login-icon-logged").classList.add("hidden");
        }
    }

    /**
     * @function handleCountryChange
     * @desc this function change the country
     * 
     * @param {value}
     */
    handleCountryChange = (value) => {
        this.props.handleCountryChange(value);

        this.handleSnipcartStore(value);
    }

    /** 
     * @function submitSearch
     * @desc this function submit the search form and navigate to the search page
     * 
     * @param {e}
     */
    submitSearch = (e) => {
        e.preventDefault();
        searchText = e.target.elements.searchText.value;
        this.props.handleSearchText(searchText);
        navigate("/" + this.state.locale + "/search?term=" + searchText);
        if (this.state.isDDMOpen) {
            this.closeDDM();
        }
    }

    /**
    * @function toggleClassOpend
    * @desc adds/removes the class opend that opens or closes the main menu
    * 
    * @param {e} event
    */
   toggleClassOpend = (e) => {
       const currentButton = e.target;
       const activeMenu = currentButton.parentElement.parentElement;

       if (activeMenu.classList.contains('opend')) {
           this.closeCategoryMenu();
        } else {
            this.closeCategoryMenu();
            this.openCategoryMenu(activeMenu)
        }
    }
     
    /**
     * @function closeCategoryMenu
     * @desc Closes the main menu by removing the class opend
     */
    closeCategoryMenu = () => {
        document.querySelectorAll('.category-topmenu-item').forEach(function (activeMenu) {
            activeMenu.classList.remove('opend')
            activeMenu.classList.remove('no-hover')
        })
    } 
    
    /**
     * @function openCategoryMenu
     * @desc Opens the main menu by adding the class opend.
     * Also adds the class no-hover to avoid hover behaviour on big touch screens
     * @param {e} event
     */
    openCategoryMenu = (activeMenu) =>{
        let isAndroid = /(android)/i.test(navigator.userAgent);
        let isIOS = navigator.userAgent.match(/(iPod|iPhone|iPad)/);
        activeMenu.classList.add('opend')
        if(isAndroid||isIOS){
            activeMenu.classList.add('no-hover')
        }
    }
    
    /**
     * @function openSearchForm
     * @desc Show the search form under the header (for desktop).
     */
    openSearchForm = () =>{
        document.getElementById("formSearchDiv").classList.toggle('show');
        document.getElementById("searchText").focus()
    }

    
    render() {
        
        var data = this.props.data;

        return (
            <div className="header">
                <div className={"header__menu-fade" + (this.state.isDDMOpen ? ' active' : '')} onClick={this.closeDDM}></div>
                <div className="header__banner">
                    <GrantPopup />
                    {i18next.t("HEADER_FREE_SHIPPING")}
                </div>
                <div className="header__row">
                    <div className="header__row-conf">
                        <div className="header__row-lang" >
                            <FontAwesomeIcon className="select-arrow__icon" icon="angle-down" />
                            <select className="lang-select" id="langSelect" onChange={e => this.handleLangChange(e.target.value)} value={data.categories.edges[0].node.locale} aria-label={i18next.t("LANG_ARIA")}>
                                <option value="en">{i18next.t("LANG_ENGLISH")}</option>
                                <option value="es">{i18next.t("LANG_SPANISH")}</option>
                            </select>
                        </div>
                        <div className="header__row-country" >
                            <FontAwesomeIcon className="select-arrow__icon" icon="angle-down" />
                            <select className="country-select" id="countrySelect" onChange={e => this.handleCountryChange(e.target.value)} aria-label={i18next.t("STORE_ARIA")}>
                                <option value="eu">{i18next.t("STORE_EUROPE")}</option>
                                <option value="us">{i18next.t("STORE_US")}</option>
                                <option value="uk">{i18next.t("STORE_UK")}</option>
                                <option value="int">{i18next.t("STORE_INTERNATIONAL")}</option>
                            </select>
                        </div>
                        <div className="header__row-currency">
                            <FontAwesomeIcon className="select-arrow__icon" icon="angle-down" />
                            <select className="currency-select" id="currencySelect" onChange={e => this.props.handleCurrencyChange(e.target.value)} aria-label={i18next.t("CURRENCY_ARIA")}>
                                <option value="USD" id="currencySelectUSD">{i18next.t("CUR_USD")}</option>
                                <option value="EUR" id="currencySelectEUR">{i18next.t("CUR_EUR")}</option>
                                <option value="GBP" id="currencySelectGBP">{i18next.t("CUR_GBP")}</option>
                            </select>
                        </div>
                    </div>
                    <div className="header__row-logo">
                        <Link to={'/' + this.state.locale + '/'}>
                            <img className="lazyload" data-src={logoSVG} alt="eyewear concept logo" />
                        </Link>
                    </div>
                    <div className="header__row-icons">
                        <div className="header__login" onMouseEnter={this.isLoggedFunction}>
                            <img className={"lazyload header__login-icon " + (this.state.isLogged ? "logged" : "")} data-src={loginIcon} alt="login-logout" />
                            <div className={'header__login-wrapper ' + (this.state.isLogged ? 'login-logged' : 'login-guest')}>
                                <a href="#" className="snipcart-customer-signin">{this.state.isLogged ? i18next.t("LOGIN_ORDERS") : i18next.t("LOGIN_LOGIN")}</a>
                                <a href="#" className="snipcart-user-logout" onMouseUp={this.closeLoginPopupOnLogout}>{i18next.t("LOGIN_LOGOUT")}</a>
                            </div>
                        </div>
                        <div className="header__cart">
                            <a href="" className="header__summary snipcart-summary snipcart-checkout" aria-label={i18next.t("HEADER_CART_BUTTON_ARIA")}>
                                <img className="lazyload header__summary-icon" data-src={cartIcon} alt="cart" />
                                <div className="header__summary-total">
                                    <span className="snipcart-items-count">0</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="header__logo">
                    <Link to={'/' + this.state.locale + '/'}>
                        <img className="lazyload" data-src={logoSVG} alt="eyewear concept logo" />
                    </Link>
                </div>
                <div className="header__menu" >
                    <div className="header__menu-icon mobile" onClick={this.openDDM}>
                        <FontAwesomeIcon className="icon-bars" icon="bars" />
                    </div>
                    <div className={"menu " + (this.state.isDDMOpen ? 'opend' : '')}>
                        <div className="menu__sections">
                            <div className="category-topmenu" onMouseLeave={this.closeCategoryMenu} >
                                <div className="menu__close-icon mobile" onClick={this.closeDDM}>
                                    <FontAwesomeIcon className="icon-close" icon="times" />
                                </div>
                                {
                                    data.ddm.nodes.map((item) => (
                                        item.submenu.map((ddmHeader) => (
                                            <div key={ddmHeader.header} className="category-topmenu-item">
                                                <div className="category-topmenu__button-wrapper">
                                                    { ddmHeader.menu && ddmHeader.menu !== '' && 
                                                        <button type="button" className="category-topmenu__button mobile" onClick={this.toggleClassOpend}>
                                                            {ddmHeader.header}
                                                        </button>
                                                    }
                                                    { ddmHeader.menu && ddmHeader.menu !== '' && 
                                                        <Link to={ddmHeader.directLink} className="category-topmenu__button desktop" onMouseOver={this.toggleClassOpend} onMouseOut={this.toggleClassOpend}>
                                                            {ddmHeader.header}
                                                        </Link>
                                                    }
                                                    { (!ddmHeader.menu || ddmHeader.menu === '') && ddmHeader.directLink &&  
                                                        <Link to={ddmHeader.directLink} className="category-topmenu__button link">
                                                            {ddmHeader.header}
                                                        </Link>
                                                    }
                                                </div>
                                                { ddmHeader.menu && ddmHeader.menu !== '' && 
                                                    <div className="category-submenu">
                                                        <div className="category-submenu__closebtn-wrapper">
                                                            <button type="button" className="category-submenu__closebtn" onClick={this.closeCategoryMenu}>
                                                                <FontAwesomeIcon className="select-arrow__icon" icon="angle-down" />
                                                            </button>
                                                        </div>
                                                        
                                                        <div className="category-submenu-links-wrapper">
                                                        { 
                                                            JSON.parse(ddmHeader.menu).columns.map((column, index) => (
                                                                <div key={"column" + index} className={"category-submenu-links-columns width" + column.numElements}>
                                                                    {
                                                                        column.options.map((option) => (
                                                                                option.suboptions ?
                                                                                    <div key={option.text}>
                                                                                        <div className={"category-submenu-links-hide desktop"}>
                                                                                            <span className="category-submenu-links-columns-mainoption">{option.text}</span>
                                                                                            <div className={"numElements numElements" + column.numElements}>
                                                                                                {option.suboptions.map((suboption) => (
                                                                                                    <Link to={suboption.link}  key={suboption.text} className={"category-submenu__link style-" + column.style}>
                                                                                                        {suboption.image && <img className="lazyload" data-src={suboption.image} alt={suboption.text}/>}
                                                                                                        <span>{suboption.text}</span>
                                                                                                    </Link>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={"category-submenu-links-hide mobile"}>
                                                                                            <span className="category-submenu-links-columns-mainoption">{option.text}</span>
                                                                                            {column.style === 'text' ?
                                                                                                <div className={"numElements numElements" + column.numElements}>
                                                                                                    {option.suboptions.map((suboption) => (
                                                                                                        <Link to={suboption.link}  key={suboption.text} className={"category-submenu__link style-" + column.style}>
                                                                                                            {suboption.image && <img className="lazyload" data-src={suboption.image} alt={suboption.text}/>}
                                                                                                            <span>{suboption.text}</span>
                                                                                                        </Link>
                                                                                                    ))}
                                                                                                </div>
                                                                                            :
                                                                                                <Carousel {...menuSettings} className="category-submenu-links-mobilecarousel">
                                                                                                    {option.suboptions.map((suboption) => (
                                                                                                        <Link to={suboption.link}  key={suboption.text} className={"category-submenu__link style-" + column.style}>
                                                                                                            {suboption.image && <img className="lazyload" data-src={suboption.image} alt={suboption.text}/>}
                                                                                                            <span>{suboption.text}</span>
                                                                                                        </Link>
                                                                                                    ))}
                                                                                                </Carousel>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                :
                                                                                    <span key={option.text} className="category-submenu-links-columns-mainoption"><Link to={option.link} className="category-submenu__link">{option.text}</Link></span>
                                                                        ))
                                                                    }
                                                                    {
                                                                        column.bottomLink &&
                                                                        <div className="category-submenu-links-bottom">
                                                                            <Link to={column.bottomLink.link}  key={column.bottomLink.text}>
                                                                                {column.bottomLink.text}
                                                                            </Link>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    ))
                                }
                                
                            </div>

                            <ul className="menu__content-pages">
                                {
                                    data.contentPages.edges.map(({ node: contentPage }) => (
                                        <li key={contentPage.cpContentGridTitle}>
                                            <Link to={'/' + contentPage.locale + '/' + contentPage.url} className="" onMouseUp={this.closeDDM}>{contentPage.cpContentGridTitle}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>

                        <div className="menu__searchmobile">
                            <form id="search" onSubmit={this.submitSearch}>
                                <input type="text" name="searchText" placeholder={i18next.t("HEADER_SEARCH_PLACEHOLDER")} aria-label={i18next.t("HEADER_SEARCH_ARIA")} />
                                <button type="submit" name="searchSubmit" className="search__button" aria-label={i18next.t("HEADER_SEARCH_BUTTON_ARIA")}>
                                    <img className="lazyload" data-src={searchIcon} alt="search icon" />
                                </button>
                            </form>
                        </div>

                        <div className="menu__search-action">
                            <button name="showSubmit" className="search__button" aria-label={i18next.t("HEADER_SEARCH_BUTTON_ARIA")} onClick={this.openSearchForm}>
                                <span>{i18next.t("HEADER_SEARCH_ARIA")}</span>
                                <img className="lazyload" data-src={searchIcon} alt="search icon" />
                            </button>
                        </div>
                    </div>
                    <div className="menu__search" id="formSearchDiv">
                        <form id="search" onSubmit={this.submitSearch}>
                            <input type="text" name="searchText" id="searchText" placeholder={i18next.t("HEADER_SEARCH_ARIA")} aria-label={i18next.t("HEADER_SEARCH_ARIA")} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;